@import '../../../../scss/theme-bootstrap';

.product-sku-price {
  @include breakpoint($medium-up) {
    display: inline-block;
  }
  &__value {
    @include font--text-alt;
    font-size: 30px;
  }
}
